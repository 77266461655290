import React from 'react';
import { TimelineMax } from 'gsap';
import Styles from './heroSection.module.scss';
import { cursorPointer } from '../../../utils';
import Logo from '../../../images/stellar-logo-stacked.svg';
import { ParallaxStarsBackground } from '../../parallaxStarsBackground';

class HeroSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showHoverText: true,
			previousTimeline: ''
		};
	}

	displayInfoText(displayCheck) {
		if (this.state.previousTimeline) {
			this.state.previousTimeline.kill();
		}

		const mainLandingInfoText = document.getElementById('mainLandingInfoText');
		const showInfoTextAnimation = new TimelineMax();

		cursorPointer(displayCheck);

		if (displayCheck) {
			this.setState({ showHoverText: false });

			showInfoTextAnimation.from(mainLandingInfoText, 0, { y: '10', opacity: '0' })
				.to(mainLandingInfoText, 0.5, { y: '0', opacity: '1' })
				.to('.mainLandingHoverText', 0.5, { opacity: '0' }, 0);
		} else {
			showInfoTextAnimation.from(mainLandingInfoText, 0, { y: '0', opacity: '1' })
				.to(mainLandingInfoText, 1, { y: '0', opacity: '1' })
				.to(mainLandingInfoText, 0.5, { y: '10', opacity: '0' })
				.to('.mainLandingHoverText', 0.2, { opacity: '1' })
				.to('.mainLandingHoverText', 0.2, { opacity: '0' }, 10);
		}

		this.setState({ previousTimeline: showInfoTextAnimation });
	}

	componentDidMount() {
		this.displayInfoText(true);
		cursorPointer(false);

		setTimeout(() => {
			this.displayInfoText(false);
		}, 5000);
	}

	render() {
		return (
			<div id="mainLandingSection" className={Styles.mainContainer}>
				<ParallaxStarsBackground
					componentToRender={
						<div>
							<div data-depth="0.2" className={Styles.textContainer}>
								<div className={Styles.logoContainer}>
									<p className={`mainLandingHoverText text-right ${Styles.hoverText}`} onMouseOver={() => { this.displayInfoText(true); }} onMouseOut={() => this.displayInfoText(false)}>Hover</p>
									<p className={`mainLandingHoverText text-right ${Styles.tapText}`}>Tap</p>
									<img src={Logo} alt="logo" className={Styles.logo} onMouseOver={() => { this.displayInfoText(true); }} onMouseOut={() => this.displayInfoText(false)} />
								</div>
								<div className={Styles.infoTextContainer}>
									<p
										id="mainLandingInfoText"
										className={Styles.infoText}
										onMouseOver={() => {
											this.displayInfoText(true);
											cursorPointer(false);
										}}
										onMouseOut={() => this.displayInfoText(false)}>
											As your remote product team Stellar Minore transforms your company,
											in this vast sea of stars, to one that shines even brighter.
											Let our technological competence fly you into the future of tech.
									</p>
								</div>
							</div>
						</div>
					}
				/>
			</div>
		);
	}
}

export { HeroSection };
