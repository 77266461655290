import React from 'react';
import Styles from './comingSoon.module.scss';
import { ParallaxStarsBackground } from '../parallaxStarsBackground';

class ComingSoon extends React.Component {
	render() {
		return (
			<div id="comingSoonPage" className={Styles.comingSoonContainer}>
				<ParallaxStarsBackground
					componentToRender={
						<div className={Styles.contentContainer}>
							<p className={Styles.comingSoonText}>Coming Soon</p>
							<div className={Styles.scene}>
								<div className={Styles.moonShadow}></div>
								<div className={Styles.moonContainer}>
									<div className={Styles.moon}>
										<div className={Styles.eyes}>
											<div className={`${Styles.eye} ${Styles.leftEye}`}></div>
											<div className={`${Styles.eye} ${Styles.rightEye}`}></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				/>
			</div>
		);
	}
}

export { ComingSoon };
