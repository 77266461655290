import React from 'react';
import emailjs from '@emailjs/browser';
import gsap from 'gsap';
import Lottie from 'lottie-web';
import Styles from './contactUsScreen.module.scss';
import { updateCursor, cursorPointer, disableCursor } from '../../utils';
import SuccessLottie from '../../lotties/success.json';
import EnvelopeLottie from '../../lotties/envelope.json';
import SpinnerLottie from '../../lotties/spinner.json';
import { ReactComponent as PencilIcon } from '../../images/contactUs/pencilIcon.svg';
import { ReactComponent as WrenchIcon } from '../../images/contactUs/wrenchIcon.svg';
import { ReactComponent as ThreeDotsIcon } from '../../images/contactUs/threeDotsIcon.svg';
import { ReactComponent as BulbIcon } from '../../images/contactUs/bulbIcon.svg';
import { ParallaxStarsBackground } from '../parallaxStarsBackground';

class ContactUsScreen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			step: 1,
			showSendButton: false,
			category: '',
			categoryIcon: '',
			name: '',
			email: '',
			message: '',
			disableNextButton: true,
			disableSendButton: false,
			showLottie: false,
			showSpinner: false,
			showSuccess: false
		};
	}

	stepComplete(stepNumber, previousStep = false) {
		const prevStepAnimation = gsap.timeline();
		const nextStepAnimation = gsap.timeline();
		const prevStepSelector = (previousStep) ? (`contactUsStep${stepNumber}`) : (`contactUsStep${stepNumber}`);
		const nextStepSelector = (previousStep) ? (`contactUsStep${stepNumber - 1}`) : (`contactUsStep${stepNumber + 1}`);
		const inputFieldSelector = (previousStep) ? (`contactUsInput${stepNumber}` - 1) : (`contactUsInput${stepNumber}`);

		this.setState({
			step: (previousStep) ? (stepNumber - 1) : (stepNumber + 1),
			showSendButton: !!((stepNumber === 3 && !previousStep)),
			name: this.state.name
		});

		document.getElementById(nextStepSelector).style.visibility = 'visible';
		document.getElementById(prevStepSelector).style.visibility = 'hidden';

		prevStepAnimation.to(`#${prevStepSelector}`, 0.3, { opacity: '0' })
			.to(`#${prevStepSelector}`, 0, { y: '+=10' });

		nextStepAnimation.to(`#${nextStepSelector}`, 0.4, { opacity: '1', y: '-=5' });

		const nextButton = document.querySelector('#nextButton');
		if (nextButton && nextButton.matches(':hover')) {
			disableCursor(
				(stepNumber === 1 && !this.state.name)
				|| (
					stepNumber === 2
					&& (!this.state.email.value || this.state.email.validity.patternMismatch))
				|| false
			);
		}

		setTimeout(() => {
			if (document.getElementById(inputFieldSelector)) {
				document.getElementById(inputFieldSelector).focus();

				this.setState({
					disableNextButton:
						!((this.state.name && stepNumber === 1)
						|| (
							!this.state.email.validity?.patternMismatch
							&& stepNumber === 2
							&& this.state.email.value
						))
				});
			}
		}, 400);
	}

	backButtonClick(stepNumber) {
		this.setState({
			step: stepNumber - 1,
			disableNextButton: false
		});

		this.stepComplete(stepNumber, true);
	}

	nextButtonClick(stepNumber) {
		this.setState({
			step: stepNumber + 1,
			disableNextButton: true
		});

		if (stepNumber === 2 && this.state.email?.value?.length !== 0) {
			this.setState({ disableNextButton: false });
		}

		this.stepComplete(stepNumber);
	}

	handleInput(stepNumber, e) {
		if ((stepNumber === 2 && this.state.name.length > 0)
		|| (stepNumber === 3 && this.state.email?.value?.length > 0
		&& !this.state.email?.validity.patternMismatch)) {
			this.setState({ disableNextButton: false });
			disableCursor(false);

			if (e.keyCode === 13) {
				this.stepComplete(stepNumber);

				this.setState({ disableNextButton: true });
			}
		} else if (stepNumber === 4) {
			this.setState({ disableSendButton: false });
		} else {
			this.setState({
				disableNextButton: true
			});

			const nextButton = document.querySelector('#nextButton');
			if (nextButton.matches(':hover')) {
				disableCursor(true);
			}
		}
	}

	textAreaFocus(e) {
		const savedValue = e.target.value;

		e.target.value = '';
		e.target.baseScrollHeight = e.target.scrollHeight;
		e.target.value = savedValue;
	}

	textAreaInput(e) {
		const minRows = parseInt(e.target.getAttribute('data-min-rows'), 10) || 0;

		e.target.rows = minRows;

		const rows = (e.target.scrollHeight - 26) / 26;

		e.target.rows = minRows + rows;
	}

	async sendMessage() {
		const stepAnimation = gsap.timeline();

		stepAnimation.to('#contactUsStep4', 0.3, { opacity: '0', y: '+=10' });

		document.getElementById('contactUsStep4').style.visibility = 'hidden';

		await this.setState({
			showLottie: true,
			showSpinner: true
		});

		Lottie.loadAnimation({
			container: document.querySelector('#spinnerLottieContainer'),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: SpinnerLottie
		});

		const contactCategory = (this.state.category === '') ? ('Other') : (this.state.category);

		emailjs.send('service_481iqvi', 'template_k062d6l', {
			from_name: this.state.name,
			message: this.state.message,
			reply_to: 'info@stellarminore.com',
			category: contactCategory,
			from_email: this.state.email.value,
		}).then(async (message) => {
			if (message !== 'OK') {
				console.log(message);
			}

			await this.setState({
				showSpinner: false,
				showSuccess: true
			});

			Lottie.loadAnimation({
				container: document.querySelector('#successLottieContainer'),
				renderer: 'svg',
				loop: false,
				autoplay: true,
				animationData: SuccessLottie
			});
		});
	}

	componentDidMount() {
		document.querySelectorAll('.pointer').forEach((item) => {
			item.addEventListener('mouseover', () => {
				item.style.cursor = 'none !important';

				cursorPointer(true);
			});

			item.addEventListener('mouseout', () => {
				cursorPointer(false);
			});
		});

		Lottie.loadAnimation({
			container: document.querySelector('#envelopeLottieContainer'),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: EnvelopeLottie
		});

		emailjs.init('4hY8MRZL1ohXY1-4l');
	}

	render() {
		return (
			<div id="contactUsPage" className={Styles.mainContainer}>
				<div className={Styles.navbar} />
				<div>
					<ParallaxStarsBackground
						isHeader="SMALL"
						componentToRender={
							<div data-depth="0.2" className={Styles.screenTitle}>
								<h1 className={`font-weight-bold text-center ${Styles.title}`}>Contact Us</h1>
							</div>
						}
					/>
				</div>
				<div id="contactUsFormContainer" className={Styles.contactFormContainer} onMouseEnter={() => updateCursor('blue')}>
					{
						(!this.state.showLottie) ? (
							<>
								<div className={`${Styles.envelopeLottieContainer} ${!this.state.showSpinner ? 'visible' : 'invisible'}`}>
									<div id="envelopeLottieContainer" />
								</div>
								<div className={Styles.stepsContainer}>
									<div id="contactUsStep1" className={Styles.step}>
										<h4 className={Styles.stepHeading}>
											Thanks for your interest in reaching out to us.
										</h4>
										<h4 className={`${Styles.stepHeading} mt-n4`}>What can we help you with today?</h4>
										<div className={`w-100 ${Styles.categoriesContainer}`}>
											<div
												className={Styles.category}
												onClick={() => { this.stepComplete(1); this.setState({ category: 'strategy' }); }}
												onMouseEnter={() => {
													this.setState({ categoryIcon: 'strategy' });
													cursorPointer(true);
												}}
												onMouseLeave={() => {
													this.setState({ categoryIcon: '' });
													cursorPointer(false);
												}}>
												<div className={Styles.categoryIcon}>
													<BulbIcon className={Styles.categoryIconSvg} />
												</div>
												<h5>Strategy</h5>
											</div>
											<div
												className={Styles.category}
												onClick={() => { this.stepComplete(1); this.setState({ category: 'design' }); }}
												onMouseEnter={() => {
													this.setState({ categoryIcon: 'design' });
													cursorPointer(true);
												}}
												onMouseLeave={() => {
													this.setState({ categoryIcon: '' });
													cursorPointer(false);
												}}>
												<div className={Styles.categoryIcon}>
													<PencilIcon className={Styles.categoryIconSvg} />
												</div>
												<h5>Design</h5>
											</div>
											<div
												className={Styles.category}
												onClick={() => { this.stepComplete(1); this.setState({ category: 'development' }); }}
												onMouseEnter={() => {
													this.setState({ categoryIcon: 'development' });
													cursorPointer(true);
												}}
												onMouseLeave={() => {
													this.setState({ categoryIcon: '' });
													cursorPointer(false);
												}}>
												<div className={Styles.categoryIcon}>
													<WrenchIcon className={Styles.categoryIconSvg} />
												</div>
												<h5>Development</h5>
											</div>
											<div
												className={Styles.category}
												onClick={() => { this.stepComplete(1); this.setState({ category: 'other' }); }}
												onMouseEnter={() => {
													this.setState({ categoryIcon: 'other' });
													cursorPointer(true);
												}}
												onMouseLeave={() => {
													this.setState({ categoryIcon: '' });
													cursorPointer(false);
												}}>
												<div className={Styles.categoryIcon}>
													<ThreeDotsIcon className={Styles.categoryIconSvg} />
												</div>
												<h5>Other</h5>
											</div>
										</div>
									</div>
									<div id="contactUsStep2" className={Styles.step}>
										{
											(this.state.category)
												? (
													<h4 className={Styles.stepHeading}>
														Who do we have the pleasure of talking about <span className="font-italic">{this.state.category}</span> with?
													</h4>
												) : (
													<h4 className={Styles.stepHeading}>Who are we talking to?</h4>
												)
										}
										<input
											id="contactUsInput1"
											autoComplete="off"
											name="name"
											className={`pointer text-center ${Styles.input}`}
											placeholder="Enter your name/your company's name"
											required
											onKeyUp={async (e) => {
												await this.setState({ name: e.target.value }); this.handleInput(2, e);
											}} />
									</div>
									<div id="contactUsStep3" className={Styles.step}>
										<h4 className={Styles.stepHeading}>
											Glad to be chatting with you, {this.state.name}!
										</h4>
										<h4 className={`${Styles.stepHeading} mt-n2`}>
											What is the best email to respond to you at?
										</h4>
										<input
											id="contactUsInput2"
											autoComplete="off"
											name="email"
											className={`pointer text-center ${Styles.input}`}
											placeholder="youremail@gmail.com"
											type="email"
											pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
											required
											onKeyUp={async (e) => {
												await this.setState({ email: e.target }); this.handleInput(3, e);
											}} />
									</div>
									<div id="contactUsStep4" className={Styles.step}>
										<h4 className={Styles.stepHeading}>
											Anything else you want to add before someone from our team reaches out to you?
										</h4>
										<textarea
											id="contactUsInput3"
											autoComplete="off"
											name="message"
											className={`pointer text-center col-8 ${Styles.input} ${Styles.messageInput}`}
											placeholder="We're all ears!"
											required
											rows="1"
											data-min-rows="1"
											onInput={this.textAreaInput}
											onFocus={this.textAreaFocus}
											onKeyUp={async (e) => {
												await this.setState({ message: e.target.value }); this.handleInput(4, e);
											}} />
									</div>
									<div className={`mt-5 ${Styles.navigationButtons}`}>
										{
											(this.state.step === 1)
												? (null)
												: (<button
													className={Styles.prevBtn}
													onClick={() => { this.backButtonClick(this.state.step); }}
													onMouseOver={() => cursorPointer(true) }
													onMouseOut={() => cursorPointer(false) }>Back</button>)
										}
										{
											this.state.step !== 1 && this.state.step !== 4
												? <span
													id="nextButton"
													onMouseEnter={() => disableCursor(this.state.disableNextButton) }
													onMouseLeave={() => disableCursor(false) }>
													<button
														className={`${Styles.nextBtn} ${(this.state.disableNextButton) ? (Styles.disabledNextBtn) : (null)}`}
														onClick={() => this.nextButtonClick(this.state.step) }
														onMouseOver={() => cursorPointer(true) }
														onMouseOut={() => cursorPointer(false) }>
															Next
													</button>
												</span>
												: null
										}
										{
											this.state.showSendButton
												? <button
													className={`${Styles.sendBtn} ${(this.state.disableSendButton) ? (Styles.disabledSendBtn) : (null)}`}
													onMouseOver={() => cursorPointer(true) }
													onMouseOut={() => cursorPointer(false) }
													onClick={() => this.sendMessage() }>Send
												</button>
												: null
										}
									</div>
								</div>
							</>
						) : (
							<>
								<div className={`${Styles.envelopeLottieContainer} ${!this.state.showSpinner ? 'visible' : 'invisible'}`}>
									<div id="envelopeLottieContainer" />
								</div>
								{
									this.state.showSuccess
									&& <>
										<div className="text-center pt-5">
											<h4 className={`${Styles.stepHeading} pt-2`}>We’ve recieved your response and</h4>
											<h4 className={`${Styles.stepHeading} mt-n4`}>somebody will get back to you shortly</h4>
										</div>
										<div className={Styles.successLottieContainer}>
											<div id="successLottieContainer" className={Styles.showLottie} />
										</div>
									</>
								}
								{
									this.state.showSpinner
									&& <div className='pt-5'>
										<div id="spinnerLottieContainer" className={Styles.showLottie} />
									</div>
								}
							</>
						)
					}
				</div>
			</div>
		);
	}
}

export { ContactUsScreen };
