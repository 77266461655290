import React from 'react';
import Styles from './codeSection.module.scss';
import CodeBackground from '../../../images/code-block.png';
import { updateCursor } from '../../../utils';

class CodeSection extends React.Component {
	render() {
		return (
			<div id="codeSection" className={Styles.mainContainer} onMouseEnter={() => updateCursor('white')}>
				<div className={Styles.imageContainer}>
					<img src={CodeBackground} alt="code" />
				</div>
				<div className={Styles.contentContainer}>
					<h3 className="font-weight-bold mb-5">No Code/Low Code Solutions</h3>
					<p>
						Engineering development cycles can be expensive and
						sometimes all you need is a quick update.
					</p>
					<p>
						We are experts at creating no code/low code solutions so that you can
						make changes to your content without having to spend on costly
						development cycles and focus your spend on other areas of your business.
					</p>
				</div>
			</div>
		);
	}
}

export { CodeSection };
