import React from 'react';
import { Link } from 'react-router-dom';
import Styles from './footer.module.scss';
import { updateCursor, cursorPointer } from '../../utils';
import Logo from '../../images/stellar-logo.svg';

class Footer extends React.Component {
	componentDidMount() {
		document.querySelectorAll('.pointer').forEach((item) => {
			item.addEventListener('mouseover', () => {
				item.style.cursor = 'none !important';
				cursorPointer(true);
			});

			item.addEventListener('mouseout', () => {
				cursorPointer(false);
			});
		});
	}

	render() {
		return (
			<div className={Styles.footer} onMouseEnter={() => updateCursor('white')}>
				<div className={`row ${Styles.footerContent}`}>
					<div className={`col-12 col-lg-6 text-left ${Styles.logoContainer}`}>
						<img src={Logo} alt="logo" className={Styles.logo} />
						<p className={Styles.footerText}>
							Making your star brighter, far into the Stellar future
						</p>
					</div>
					<h6 className="font-weight-bold mt-5 mb-0 d-lg-none d-block">Useful Links</h6>
					<div className={`col-12 col-lg-6 text-right mt-3 ${Styles.linksContainer}`}>
						<Link to={'/projects'} className={`pointer ${Styles.link}`}>All Projects</Link>
						<span className="mx-2 d-lg-none d-block">•</span>
						<Link to={'/contact'} className={`pointer ${Styles.link}`}>Contact Us</Link>
					</div>
				</div>
				<p className="w-100 text-center m-0 pb-2"><span>&copy;</span>{new Date().getFullYear()} Stellar Minore LLC • All Rights Reserved</p>
			</div>
		);
	}
}

export { Footer };
