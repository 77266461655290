import { React, useLayoutEffect } from 'react';
import {
	Routes, Route, useLocation
} from 'react-router-dom';
import Styles from './app.module.scss';
import { AppNavbar } from '../navbar';
import { AlveaScreen } from '../projectDetails/alvea';
import { Cursor } from '../cursor';
import { Careers } from '../careers';
import { ComingSoon } from '../comingSoon';
import { ContactUsScreen } from '../contactUsScreen';
import { ErrorScreen } from '../errorScreen';
import { Footer } from '../footer';
import { GroupLoopScreen } from '../projectDetails/groupLoop';
import { LandingScreen } from '../landingScreen';
import { ProjectsScreen } from '../projectsScreen';
import { TelisScreen } from '../projectDetails/telis/telis';
import { TreasureScreen } from '../projectDetails/treasure';
import { ZetaScreen } from '../projectDetails/zeta';

function App() {
	window.addEventListener('mousemove', (e) => {
		const cursor = document.getElementById('cursor');

		cursor.style.left = `${e.clientX}px`;
		cursor.style.top = `${e.clientY}px`;
	});

	const Wrapper = ({ children }) => {
		const location = useLocation();

		useLayoutEffect(() => {
			document.documentElement.scrollTo({ top: 0, behavior: 'instant' });
		}, [location.pathname]);

		return children;
	};

	return (
		<Wrapper>
			<div className={Styles.app}>
				<Cursor />
				<Layout>
					<AppNavbar />
					<Routes>
						{/* careers screen */}
						<Route path="/careers" element={<Careers /> } />

						{/* contact us */}
						<Route path="/contact" element={<ContactUsScreen />} />

						{/* coming soon */}
						<Route path="/coming-soon" element={<ComingSoon />} />

						{/* projects screen */}
						<Route path="/projects" element={<ProjectsScreen />} />

						{/* Page not found screen  */}
						<Route path="/page-not-found" element={<ErrorScreen />} />

						{/* Alvea project screen */}
						<Route path="/projects/alvea" element={<AlveaScreen />} />

						{/* GroupLoop project screen */}
						<Route path="/projects/grouploop" element={<GroupLoopScreen />} />

						{/* Telis project screen */}
						<Route path="/projects/telis" element={<TelisScreen />} />

						{/* Treasure project screen */}
						<Route path="/projects/treasure" element={<TreasureScreen />} />

						{/* Zeta project screen */}
						<Route path="/projects/zeta" element={<ZetaScreen />} />

						{/* Catch-all route for page not found */}
						<Route path="*" element={<ErrorScreen />} />

						<Route exact path="/" element={<LandingScreen />} />
					</Routes>
				</Layout>
				<Footer />
			</div>
		</Wrapper>
	);
}

const Layout = ({ children }) => (
	<div className={Styles.mainContainer}>
		<div className={Styles.contentContainer}>
			{children}
		</div>
	</div>
);

export { App };
