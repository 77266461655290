import React from 'react';
import Lottie from 'lottie-web';
import tinycolor from 'tinycolor2';
import Styles from './projectsScreen.module.scss';
import 'bootstrap/js/dist/dropdown';
import { updateCursor, cursorPointer } from '../../utils';
import RocketLottie from '../../lotties/rocket.json';
import { ProjectCard } from '../projectCard';
import TagsData from '../../data/tags.json';
import ProjectsData from '../../data/projects.json';
import { ReactComponent as RemoveIcon } from '../../images/remove.svg';
import { ReactComponent as Chevron } from '../../images/chevron.svg';
import { ParallaxStarsBackground } from '../parallaxStarsBackground';

class ProjectsScreen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			projectLogoBackground: '',
			selectedTags: [],
			tags: [],
			isDropdownOpen: false,
			projectCardHover: false,
		};

		this.tagsData = TagsData.map((tagCategory) => (
			tagCategory.tags.map((tag) => tag)
		)).flatMap((innerArray) => innerArray);

		this.state.tags = this.tagsData.reduce((accumulatedTags, tag) => {
			accumulatedTags[tag.name] = tag.color;

			return accumulatedTags;
		}, {});

		this.tagNames = Object.keys(this.state.tags);

		this.tagColors = Object.values(this.state.tags);

		this.dropdownRef = React.createRef();

		this.fullCardsCount = 8;

		this.checkboxRefs = this.tagNames.reduce((accumulatedCheckboxRefs, tag) => {
			accumulatedCheckboxRefs[tag] = React.createRef();

			return accumulatedCheckboxRefs;
		}, {});
	}

	setBackgroundLogo = (logo, project) => {
		this.setState({ projectLogoBackground: logo });

		document.getElementById('backgroundLogo').style.top = `${document.querySelector(`.${project}ProjectCard`).offsetTop + document.querySelector(`.${project}ProjectCard`).offsetHeight - 120}px`;
	};

	loadRocketAnimation = () => {
		const rocketLottieContainer = document.querySelector('#rocketLottieContainer');

		if (rocketLottieContainer) {
			Lottie.loadAnimation({
				container: rocketLottieContainer,
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: RocketLottie
			});
		}
	};

	updateSelectedTagColors = () => {
		this.tagsData.forEach((tagData) => {
			const element = document.getElementById(`${tagData.name}--tagColor`);

			if (element) {
				element.style.color = tinycolor(tagData.color);
			}
		});
	};

	filterProjects = () => {
		const filteredTags = this.tagsData
			.filter((tagData) => this.state.selectedTags.includes(tagData.name))
			.map((tagData) => tagData.name);

		const projectsWithTags = ProjectsData.map((projectData) => {
			const projectTags = this.tagsData
				.filter((tagData) => projectData.tags.includes(tagData.id))
				.map((tagData) => tagData.name);

			return { ...projectData, tags: projectTags || [] };
		});

		const filteredProjects = projectsWithTags
			.filter((projectData) => this.state.selectedTags.length === 0
				|| filteredTags.some((tagID) => projectData.tags.includes(tagID)));

		this.tagColors.forEach((tagColor) => {
			const element = document.getElementById(tagColor);

			if (element) {
				element.style.color = tinycolor(tagColor);
			}
		});

		return filteredProjects;
	};

	handleOutsideClick = (e) => {
		if (this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
			this.setState({ isDropdownOpen: false });
		}
	};

	toggleCheckbox = (e) => {
		const newTag = e.target.id;

		const filteredTags = [...this.state.selectedTags];

		if (filteredTags.indexOf(newTag) !== -1) {
			filteredTags.splice(filteredTags.indexOf(newTag), 1);
		} else {
			filteredTags.push(newTag);
		}

		this.setState({ selectedTags: filteredTags });
	};

	toggleDropdown = () => {
		this.setState((prevState) => ({
			isDropdownOpen: !prevState.isDropdownOpen,
		}));
	};

	calculateCardsLayout = (index) => (index < this.fullCardsCount - 1
		? Styles.fullCard
		: Styles.halfCard);

	clearAllFilters = () => {
		this.setState({ selectedTags: [] });
	};

	componentDidMount() {
		this.loadRocketAnimation();

		this.filterProjects();

		document.addEventListener('mousedown', this.handleOutsideClick);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.selectedTags.length !== this.state.selectedTags.length) {
			this.updateSelectedTagColors();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleOutsideClick);
	}

	render() {
		const filterProjects = this.filterProjects();

		const { isDropdownOpen } = this.state;

		return (
			<div className={Styles.projectsSection}>
				<div className={Styles.headingContainer}>
					<ParallaxStarsBackground
						isHeader="SMALL"
						componentToRender={
							<div data-depth="0.2" className={Styles.projectsHeading}>
								<h1 className={`font-weight-bold text-center ${Styles.title}`}>Projects</h1>
							</div>
						}
					/>
				</div>
				<div
					id="projectsSection"
					className={Styles.projectsContainer}
					onMouseEnter={() => updateCursor('blue')}>
					<div className={`container ${Styles.filterSection}`}>
						<div className={Styles.tagsSection}>
							{this.state.selectedTags?.map((selectedTag) => (
								<button
									key={selectedTag}
									className={`${Styles.selectedTags} btn btn-secondary`}
									onClick={() => {
										this.checkboxRefs[selectedTag].current.click();
										cursorPointer(false);
									}}
									onMouseEnter={() => cursorPointer(true)}
									onMouseLeave={() => cursorPointer(false)}>
									<span
										id={`${selectedTag}--tagColor`}
										className={Styles.tagColor}>
											&#9679;
									</span>
									{selectedTag}
									<RemoveIcon className="ml-2" />
								</button>
							))}
							{this.state.selectedTags.length >= 1
							&& <p
								className={`${Styles.tagsClearAllFilters} ${this.state.projectCardHover
									? 'text-light'
									: ''
								}`}
								onClick={this.clearAllFilters}
								onMouseEnter={() => cursorPointer(true)}
								onMouseLeave={() => cursorPointer(false)}>
									Clear All Filters
							</p>
							}
						</div>
						{isDropdownOpen && (
							<div className={Styles.dropdownScreenOverlay} onClick={this.toggleDropdown} />
						)}
						<div
							className={`dropdown ${isDropdownOpen ? 'show' : ''}`}
							ref={this.dropdownRef}>
							<button
								className={`btn btn-primary ${Styles.filterDropdownButton} ${
									isDropdownOpen ? Styles.chevronUp : Styles.chevronDown
								}`}
								id="dropdownMenuButton"
								data-bs-toggle="dropdown"
								aria-expanded="false"
								onClick={this.toggleDropdown}
								onMouseEnter={() => cursorPointer(true)}
								onMouseLeave={(e) => {
									e.target.blur();
									cursorPointer(false);
								}}>
								Filter By Tag
								{isDropdownOpen
									? <Chevron className={`${Styles.chevronUp} ml-2`} />
									: <Chevron className={`${Styles.chevronDown} ml-2`} />
								}
							</button>
							<ul className={`dropdown-menu dropdown-menu-end ${Styles.dropdownMenu} ${
								isDropdownOpen ? Styles.dropdownMenuGrid : ''
							}`} aria-labelledby="dropdownMenuButton">
								{TagsData.map((tagData) => (
									<React.Fragment key={tagData.category}>
										<p className={Styles.dropdownMenuHeading}>{tagData.category}</p>
										{tagData.tags.map((tag) => (
											<li
												key={tag.name}
												className="dropdown-item"
												onClick={(e) => {
													this.toggleCheckbox(e);
													this.checkboxRefs[tag.name].current.click();
												}}
												onMouseEnter={() => cursorPointer(true)}
												onMouseLeave={() => cursorPointer(false)}>
												<div className={`${Styles.checkboxSection} form-check text-left`}>
													<input
														ref={this.checkboxRefs[tag.name]}
														className={`${Styles.checkbox} form-check-input`}
														type="checkbox"
														id={tag.name}
														checked={this.state.selectedTags.includes(tag.name)}
														onChange={(e) => {
															this.toggleCheckbox(e);
														}} />
													<span
														id={tag.color}
														className={Styles.tagColor}>
														&#9679;
													</span>
													<label className="form-check-label">
														{tag.name}
													</label>
												</div>
											</li>
										))}
									</React.Fragment>
								))}
								<hr className={Styles.dropdownMenuDivider} />
								<p
									className={Styles.dropdownClearAllFilters}
									onClick={this.clearAllFilters}
									onMouseEnter={() => cursorPointer(true)}
									onMouseLeave={() => cursorPointer(false)}>
										Clear All Filters
								</p>
							</ul>
						</div>
					</div>
					<div>
						<div className={`container ${Styles.projectsCards}`}>
							{filterProjects.length > 0
							&& filterProjects.map((project, index) => (
								<div
									key={index}
									className={this.calculateCardsLayout(index)}
									onMouseOver={() => this.setState({ projectCardHover: true })}
									onMouseOut={() => this.setState({ projectCardHover: false })}>
									<ProjectCard
										key={index}
										index={index}
										name={project.name}
										color={project.color}
										url={project.url}
										description={project.description}
										tags={project.tags}
										setBackgroundLogo={this.setBackgroundLogo}
										fullCardsCount={this.fullCardsCount}
									/>
								</div>
							))}
							<div
								className={`${Styles.comingSoonCardContainer} ${Styles.halfCard}`}>
								<div
									className={`${Styles.comingSoonCard} ${Styles.halfCard}`}
									onMouseEnter={() => cursorPointer(true)}
									onMouseLeave={() => {
										cursorPointer(false);
										updateCursor('blue');
									}}>
									<div className={Styles.comingSoonLogo}>
										<div id="rocketLottieContainer" />
									</div>
									<p className={Styles.halfCard}>More projects coming soon!</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="backgroundLogo" className={Styles.backgroundLogoContainer}>
					<img
						className={Styles.projectLogoBackground}
						src={this.state.projectLogoBackground}
						alt={(this.state.projectLogoBackground) ? ('Project logo') : (' ')} />
				</div>
			</div>
		);
	}
}

export { ProjectsScreen };
