import React from 'react';
import Styles from './landingScreen.module.scss';
import { CodeSection } from './codeSection';
import { ContactUsSection } from './contactUsSection';
import { HeroSection } from './heroSection';
import { TechHireSection } from './techHireSection';
import { IntegrationsSection } from './integrationsSection';
import { ProjectsSection } from './projectsSection';
import { ProspectusSection } from './prospectusSection';
import { UniqueSolutionsSection } from './uniqueSolutionsSection';
import { SolutionsSection } from './solutionsSection';
import { StellarPromiseSection } from './stellarPromiseSection';
import { TreeLineSection } from './treeLineSection';
// import { TrueCostSection } from './trueCostSection';

class LandingScreen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			sectionIndex: 1,
		};
	}

	componentDidMount() {
		window.history.scrollRestoration = 'manual';

		window.scroll(0, 0);
		window.addEventListener('scroll', () => this.handleScroll());
	}

	scrollToSection(sectionIndex, sectionSelector, offset = 0) {
		window.scrollTo({
			top: document.querySelector(sectionSelector).offsetTop + offset,
			behavior: 'smooth'
		});
	}

	handleScroll() {
		const prospectusSection = document.querySelector('#prospectusSection');
		const uniqueSolutionsSection = document.querySelector('#uniqueSolutionsSection');
		const solutionsSection = document.querySelector('#solutionsSection');
		const techHireSection = document.querySelector('#techHireSection');
		const integrationsSection = document.querySelector('#integrationsSection');
		const codeSection = document.querySelector('#codeSection');
		// const trueCostSection = document.querySelector('#trueCostSection');
		const projectsSection = document.querySelector('#projectsSection');
		const stellarPromiseSection = document.querySelector('#stellarPromiseSection');
		const scrollPadding = (window.innerHeight / 2) - 100;

		if (window.pageYOffset < window.innerHeight) {
			this.setState({ sectionIndex: 1 });
		}

		if (window.pageYOffset > prospectusSection.offsetTop - 150) {
			this.setState({ sectionIndex: 2 });
		}

		if (window.pageYOffset > solutionsSection.offsetTop - scrollPadding) {
			this.setState({ sectionIndex: 3 });
		}

		if (window.pageYOffset > techHireSection.offsetTop - scrollPadding) {
			this.setState({ sectionIndex: 4 });
		}

		if (window.pageYOffset > integrationsSection.offsetTop - scrollPadding) {
			this.setState({ sectionIndex: 5 });
		}

		if (window.pageYOffset > codeSection.offsetTop - scrollPadding) {
			this.setState({ sectionIndex: 6 });
		}

		if (window.pageYOffset > uniqueSolutionsSection.offsetTop - scrollPadding) {
			this.setState({ sectionIndex: 7 });
		}

		// if (window.pageYOffset > trueCostSection.offsetTop - scrollPadding) {
		// 	this.setState({ sectionIndex: 8 });
		// }

		if (window.pageYOffset > projectsSection.offsetTop - scrollPadding) {
			this.setState({ sectionIndex: 8 });
		}

		if (window.pageYOffset > stellarPromiseSection.offsetTop - scrollPadding) {
			this.setState({ sectionIndex: 9 });
		}
	}

	render() {
		return (
			<div>
				<HeroSection />
				<TreeLineSection />
				<ProspectusSection />
				<SolutionsSection />
				<TechHireSection />
				<IntegrationsSection />
				<CodeSection />
				<UniqueSolutionsSection />
				{/* <TrueCostSection /> */}
				<ProjectsSection />
				<StellarPromiseSection />
				<ContactUsSection />
				<div className={Styles.bulletsContainer}>
					<span className={`pointer ${(this.state.sectionIndex === 1) ? (Styles.selectedBullet) : (Styles.sectionBullet)}`} onClick={() => { this.scrollToSection(1, '#mainLandingSection'); }} />
					<span className={`pointer ${(this.state.sectionIndex === 2) ? (Styles.selectedBullet) : (Styles.sectionBullet)}`} onClick={() => { this.scrollToSection(2, '#prospectusSection', window.innerWidth < 768 ? 0 : -150); }} />
					<span className={`pointer ${(this.state.sectionIndex === 3) ? (Styles.selectedBullet) : (Styles.sectionBullet)}`} onClick={() => { this.scrollToSection(3, '#solutionsSection'); }} />
					<span className={`pointer ${(this.state.sectionIndex === 4) ? (Styles.selectedBullet) : (Styles.sectionBullet)}`} onClick={() => { this.scrollToSection(4, '#techHireSection'); }} />
					<span className={`pointer ${(this.state.sectionIndex === 5) ? (Styles.selectedBullet) : (Styles.sectionBullet)}`} onClick={() => { this.scrollToSection(5, '#integrationsSection'); }} />
					<span className={`pointer ${(this.state.sectionIndex === 6) ? (Styles.selectedBullet) : (Styles.sectionBullet)}`} onClick={() => { this.scrollToSection(6, '#codeSection'); }} />
					<span className={`pointer ${(this.state.sectionIndex === 7) ? (Styles.selectedBullet) : (Styles.sectionBullet)}`} onClick={() => { this.scrollToSection(7, '#uniqueSolutionsSection'); }} />
					{/* <span className={`pointer ${(this.state.sectionIndex === 8)
						? (Styles.selectedBullet)
						: (Styles.sectionBullet)}`}
							onClick={() => { this.scrollToSection(8, '#trueCostSection'); }}
						/>
					*/}
					<span className={`pointer ${(this.state.sectionIndex === 8) ? (Styles.selectedBullet) : (Styles.sectionBullet)}`} onClick={() => { this.scrollToSection(7, '#projectsSection'); }} />
					<span className={`pointer ${(this.state.sectionIndex === 9) ? (Styles.selectedBullet) : (Styles.sectionBullet)}`} onClick={() => { this.scrollToSection(8, '#stellarPromiseSection'); }} />
				</div>
			</div>
		);
	}
}

export { LandingScreen };
