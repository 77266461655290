import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Styles from './stellarPromiseSection.module.scss';
import { updateCursor } from '../../../utils';
import Star from '../../../images/star.svg';

class StellarPromiseSection extends React.Component {
	componentDidMount() {
		gsap.registerPlugin(ScrollTrigger);

		const timeline = gsap.timeline();
		const fadeInAnimation = {
			opacity: 1,
			duration: 0.4
		};

		timeline.to('.stellarPromiseTitle', {
			scrollTrigger: {
				trigger: '#stellarPromiseSection',
				start: 'top top+=300'
			},
			opacity: 1,
			duration: 0.4,
			onComplete: () => {
				timeline.to('.stellarPromiseQuote', fadeInAnimation, '<0.2')
					.to('.stellarPromiseText', fadeInAnimation, '<0.2')
					.to('.contactUsSectionHeading', fadeInAnimation, '<0.2')
					.to('.contactUsSectionButton', fadeInAnimation, '<0.2');
			}
		});
	}

	render() {
		return (
			<div id="stellarPromiseSection" className={`text-center ${Styles.stellarPromiseSection}`} onMouseEnter={() => updateCursor('blue')}>
				<h2 className={`stellarPromiseTitle text-center ${Styles.sectionHeading}`}>The Stellar Promise</h2>
				<div className={`row ${Styles.sectionTextContainer}`}>
					<h3 className={`stellarPromiseQuote offset-md-2 offset-sm-0 col-md-3 col-sm-12 ${Styles.heroQuote}`}>
						We work with you to ensure your star remains brighter than all others,
						far into the stellar future.
					</h3>
					<div className={`stellarPromiseText col-md-5 ${Styles.textContainer}`}>
						<p>
							The future is rapidly changing and we believe in embracing the change.
							Innovation is critical but yesterday's innovation is outdated today.
						</p>
						<p>Our
							<span className="mx-2">Stellar Promise</span>
							is that we combine our industry experts in strategy, design, and engineering
							to deliver custom solutions that transform your organization to one that looks
							to the future but also changes the way you approach change. By helping you adopt
							change as a central part of your business, we work with you to ensure your star
							remains brighter than all others, far into the stellar future.</p>
					</div>
				</div>
				<img className={Styles.star} src={Star} alt='star' />
			</div>
		);
	}
}

export { StellarPromiseSection };
