import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { cursorPointer } from '../../utils';
import NavbarToggleIcon from '../../images/menu.png';
import CrossIcon from '../../images/cross.svg';
import Logo from '../../images/stellar-logo.svg';
import Styles from './navbar.module.scss';

function AppNavbar() {
	const location = useLocation();
	const [showMobileNavbar, setShowMobileNavbar] = useState(false);
	const [showNavbarBrand, setShowNavbarBrand] = useState(false);
	const cursorProps = (show) => ({
		onMouseOver: () => cursorPointer(true),
		onMouseOut: () => cursorPointer(false),
		onClick: () => { setShowMobileNavbar(show); }
	});

	useEffect(() => {
		setShowNavbarBrand(location.pathname !== '/');
	}, [location]);

	return (
		<Navbar collapseOnSelect expand="md" variant="dark" className={Styles.navbar}>
			<Navbar.Brand className={`${showNavbarBrand ? 'visible' : 'invisible'} ${Styles.navbarBrand}`} href="/" {...cursorProps(false)}>
				<img src={Logo} alt="logo" className={Styles.logo} />
			</Navbar.Brand>
			<Navbar.Toggle
				{...cursorProps(true)}
				className={Styles.navbarToggle}
				data-toggle="collapse"
				data-target="#navbar-collapse">
				<img className={Styles.navbarToggleIcon} src={NavbarToggleIcon} alt="navbar toggle" />
			</Navbar.Toggle>
			<Navbar.Collapse id="navbar-collapse" className={`h-100 navbar-collapse ${Styles.navbarCollapse} ${(showMobileNavbar) ? (Styles.showNavbar) : (Styles.collapseNavbar)}`}>
				{
					showMobileNavbar
					&& <button
						{...cursorProps(false)}
						className={Styles.closeNavbarToggle}>
						<img src={CrossIcon} alt="close" />
					</button>
				}
				<Nav className={`ml-auto ${Styles.navLinks}`}>
					<LinkContainer className={Styles.navLink} {...cursorProps(false)} to="/" activeClassName={Styles.activeLink}>
						<Nav.Link>Home</Nav.Link>
					</LinkContainer>
					<LinkContainer className={Styles.navLink} {...cursorProps(false)} to="/projects" activeClassName={Styles.activeLink}>
						<Nav.Link>Projects</Nav.Link>
					</LinkContainer>
					<LinkContainer className={Styles.navLink} {...cursorProps(false)} to="/contact" activeClassName={Styles.activeLink}>
						<Nav.Link>Contact Us</Nav.Link>
					</LinkContainer>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

export { AppNavbar };
