import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { updateCursor } from '../../utils';
import Styles from './twoColumnGrid.module.scss';

class TwoColumnGrid extends React.Component {
	render() {
		const {
			leftColumn,
			rightColumn,
			wrapperStyles = '',
			gridStyles,
			revertOnMobile,
			containerized = false,
			isBackgroundWhite = false
		} = this.props;

		const cursorColor = isBackgroundWhite ? 'blue' : 'white';

		return (
			<section
				className={wrapperStyles}>
				{!containerized ? (
					<div className={`${Styles.gridContainer} ${gridStyles}`} onMouseEnter={() => updateCursor(cursorColor)}>
						<div
							className={`${Styles.column} ${revertOnMobile ? Styles.reverse : ''}`}
							onMouseEnter={() => updateCursor(cursorColor)}
						>
							{leftColumn}
						</div>
						<div className={Styles.column} onMouseEnter={() => updateCursor(cursorColor)}>
							{rightColumn}
						</div>
					</div>
				) : (
					<Container onMouseEnter={() => updateCursor(cursorColor)}>
						<div className={`${Styles.gridContainer} ${gridStyles}`}>
							<div className={`${Styles.column} ${revertOnMobile ? Styles.reverse : ''}`}>
								{leftColumn}
							</div>
							<div className={Styles.column}>
								{rightColumn}
							</div>
						</div>
					</Container>
				)}
			</section>
		);
	}
}

TwoColumnGrid.propTypes = {
	leftColumn: PropTypes.node.isRequired,
	rightColumn: PropTypes.node.isRequired,
	wrapperStyles: PropTypes.string,
	gridStyles: PropTypes.string,
	revertOnMobile: PropTypes.bool,
	containerized: PropTypes.bool,
	isBackgroundWhite: PropTypes.bool
};

export { TwoColumnGrid };
