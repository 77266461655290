import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Styles from './solutionsSection.module.scss';
import { updateCursor } from '../../../utils';
import { ConveyorBelt } from '../conveyorBelt';

class SolutionsSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			solutionIndex: 0,
			showTransportCube: false,
			scrollController: '',
			mobileScreen: true,
			autoPlayCarousel: false
		};
	}

	async setsolutionIndex(index, progress) {
		const { scrollController } = this.state;

		await window.scrollTo({
			top: scrollController.start + progress * (scrollController.end - scrollController.start),
			behavior: 'smooth'
		});

		setTimeout(() => {
			this.setState({ solutionIndex: index });
		}, 20);
	}

	componentDidUpdate() {
		window.onpopstate = async () => {
			ScrollTrigger.refresh();
		};
	}

	componentDidMount() {
		ScrollTrigger.clearScrollMemory();

		setTimeout(() => {
			ScrollTrigger.matchMedia({
				'(min-width: 769px)': () => {
					this.setState({ mobileScreen: false });

					const solutionAnimation = gsap.timeline();

					solutionAnimation.to('#transportCube', 0.5, { x: '0', ease: 'none' })
						.to('#transportCube', { x: '13vw', ease: 'none' })
						.to('#transportCube', { x: '25vw', ease: 'none' })
						.to('#transportCube', { x: '38vw', ease: 'none' })
						.to('#transportCube', { x: '50vw', ease: 'none' })
						.to('#transportCube', { x: '63vw', ease: 'none' })
						.to('#transportCube', 0.5, { x: '63vw', ease: 'none' });

					const scrollController = ScrollTrigger.create({
						animation: solutionAnimation,
						trigger: '#solutionSectionPin',
						toggleActions: 'restart pause reverse pause',
						start: 'top top',
						end: '+=4000',
						pin: true,
						scrub: true,
						onUpdate: (e) => {
							if (e.progress >= 0 && e.progress < 0.23 && this.state.solutionIndex !== 0) {
								this.setState({ solutionIndex: 0 });
							} else if (e.progress >= 0.23
								&& e.progress < 0.37
								&& this.state.solutionIndex !== 1) {
								this.setState({ solutionIndex: 1 });
							} else if (e.progress >= 0.37
								&& e.progress < 0.52
								&& this.state.solutionIndex !== 2) {
								this.setState({ solutionIndex: 2 });
							} else if (e.progress >= 0.52
								&& e.progress < 0.66
								&& this.state.solutionIndex !== 3) {
								this.setState({ solutionIndex: 3 });
							} else if (e.progress >= 0.66
								&& e.progress < 0.82
								&& this.state.solutionIndex !== 4) {
								this.setState({ solutionIndex: 4 });
							} else if (e.progress >= 0.82 && this.state.solutionIndex !== 5) {
								this.setState({ solutionIndex: 5 });
							}

							if (((e.progress >= 0.18 && e.progress < 0.23)
								|| (e.progress >= 0.32 && e.progress < 0.37)
								|| (e.progress >= 0.47 && e.progress < 0.52)
								|| (e.progress >= 0.61 && e.progress < 0.66)
								|| (e.progress >= 0.76 && e.progress < 0.82))
								&& (!this.setState.showTransportCube)) {
								this.setState({ showTransportCube: true });
							} else {
								this.setState({ showTransportCube: false });
							}
						}
					});

					this.setState({ scrollController });
				},
				'(max-width: 768px)': () => {
					this.setState({ mobileScreen: true });

					const scrollController = ScrollTrigger.create({
						animation: null,
						trigger: '#solutionSectionPin',
						toggleActions: 'play none none none',
						start: 'top top',
						end: '+=100',
						onEnter: () => {
							this.setState({ autoPlayCarousel: true });
						}
					});

					this.setState({ scrollController });
				}
			});
		}, 100);

		window.addEventListener('resize', () => {
			ScrollTrigger.clearScrollMemory();
			ScrollTrigger.refresh();
		});
	}

	render() {
		const solutionAssets = require.context('../../../images/solutions', true, /\.svg$/);

		const solutionDescription = [
			<div>
				<h4>Discovery & Assessment</h4>
				<h5>
					You want to build a rocket ship but there are huge challenges that can ground your project
					long before you can even start thinking about liftoff.
					Creating value for your users while building equity in your brand
					requires careful consideration and research.
					That’s where we step in with our Discover & Assessment solutions.
				</h5>
				<h5>Delivering the external perspective you need for a successful product journey.</h5>
			</div>,
			<div>
				<h4>Strategy & Planning</h4>
				<h5>
					Project failure rarely comes from a flaw with the concept behind the project.
					It’s a result of a failure to execute,
					brought about from a lack of experience and planning.
					From designing your rocket ship, all that way to getting it into space,
					our time-tested agile Strategy & Planning solutions are honed to be efficiently lean
					but also fast so that your rocket is in the air when you want it to be.
				</h5>
				<h5>Efficiency turned all the way up to hyperdrive.</h5>
			</div>,
			<div>
				<h4>Design & Build</h4>
				<h5>
					The smallest of “ooh” and “aah” moments are what
					separate mediocre products from the best ones.
					We believe in details and every touch point along the user journey being an opportunity
					to leverage your brand values to make your target audience feel special.
					Our clients trust us to imagine and build the magical moments
					that make them stand out in this already crowded galaxy of stars.
				</h5>
				<h5>Shhh! Interactive experiential innovation in progress.</h5>
			</div>,
			<div>
				<h4>Deploy & Operationalize</h4>
				<h5>
					As the workplace gets faster, solutiones become more complex.
					Our clients cannot afford to lose speed to this ever increasing
					jungle of solutions that has become our workplace. We thrive in the complex though.
					That’s why we are brought in to implement innovative solution solutions
					or steer botched code implementations into marketable success.
					We cater to your unique needs, always keeping in mind your brand culture,
					solution methodologies, tech stack & talent abilities, to guide you to success.
				</h5>
				<h5>The complex was never so uncomplicated.</h5>
			</div>,
			<div>
				<h4>Automate & Optimize</h4>
				<h5>
					Data-driven iteration is difficult even for the largest of brands,
					yet case study after case study show the importance of carefully measuring and refining.
					From measuring increases in flow click through rate
					based off of user sentiment informed copy change
					to tracking lift in sales from updating the color of a button,
					we make the solution of data-driven iteration
					not just central to how we help clients optimize
					but help them make it a key foundation to how they approach growth.
				</h5>
				<h5>Data is innovation’s best friend.</h5>
			</div>,
			<div>
				<h4>Learning & Training</h4>
				<h5>
					User focused data-driven practices are what every company tries to
					figure out and fold into their own day to day.
					Even though the thought is appealing, actually getting this done is immensely difficult.
					Our training and workshop programs serve the purpose of taking this complicated solution
					and making it second nature for your company.
				</h5>
				<h5>Learn to fish rather than just being handed one.</h5>
			</div>
		];

		const solutionList = [
			<ul>
				<li>User Testing & Validation</li>
				<li>Consumer Data Research</li>
				<li>User Research</li>
				<li>Tech Stack Assessment</li>
				<li>Content Assessment</li>
			</ul>,
			<ul>
				<li>Growth Strategy</li>
				<li>Innovation Strategy</li>
				<li>Brand Strategy</li>
				<li>Product Strategy</li>
				<li>Business Modeling</li>
				<li>Data & Analytics Strategy</li>
			</ul>,
			<ul>
				<li>Customer Experience Design</li>
				<li>Service Design</li>
				<li>Brand Identity & Guidelines</li>
				<li>UX/UI Design</li>
				<li>Mobile App Engineering</li>
				<li>Website Development</li>
				<li>Software Development</li>
				<li>Machine Learning</li>
				<li>Admin Tool Development</li>
			</ul>,
			<ul>
				<li>Project Management</li>
				<li>Collaboration solution Management</li>
				<li>Launch Presentation</li>
				<li>Data Visualization</li>
				<li>Dashboard Development</li>
				<li>Split Traffic Testing</li>
			</ul>,
			<ul>
				<li>Conversion Rate Optimization</li>
				<li>Customer Retention Optimization</li>
				<li>Customer Journey Testing</li>
				<li>Customer Experience (CX) Testing</li>
				<li>CX Data Science</li>
				<li>A/B Testing</li>
				<li>Funnel Optimization</li>
			</ul>,
			<ul>
				<li>Workshop Design</li>
				<li>Mentoring</li>
				<li>Training</li>
			</ul>
		];

		return (
			<div id="solutionsSection">
				<div id="solutionSectionPin" className={`text-center solutionsSection ${Styles.solutionSection}`} onMouseEnter={() => updateCursor('white')}>
					<h1 className={`row text-center ${Styles.sectionHeading}`}>Our Processes</h1>
					{
						(this.state.mobileScreen) ? (
							<Carousel
								swipeable={true}
								className={`solutionsSectionCarousel ${Styles.carousel}`}
								interval={this.state.autoPlayCarousel ? 5000 : null}
								infiniteLoop={true}
								showStatus={false}
								showThumbs={false}
								useKeyboardArrows={false}
								showIndicators={true}
								stopOnHover={false}
								autoPlay={this.state.autoPlayCarousel}
								showArrows={false}>
								{
									solutionDescription.map((description, index) => (
										<div key={index} className={Styles.solutionSlide}>
											<div className={Styles.heroImageContainer}>
												<img className={Styles.heroImage} src={solutionAssets(`./hero-${index}.svg`)} alt="process hero"/>
											</div>
											<div className={Styles.descriptionContainer}>
												{description}
											</div>
										</div>
									))
								}
							</Carousel>
						) : (
							<>
								<SwitchTransition mode={'out-in'}>
									<CSSTransition key={this.state.solutionIndex} timeout={600} classNames={Styles}>
										<div className={'row mt-4 solutionContentContainer'}>
											<div className={`col-4 ${Styles.heroImageContainer}`}>
												<img className={Styles.heroImage} src={solutionAssets(`./hero-${this.state.solutionIndex}.svg`)} alt="solution hero"/>
											</div>
											<div className={`col-4 ${Styles.descriptionContainer}`}>
												{solutionDescription[this.state.solutionIndex]}
											</div>
											<div className={`col-4 text-left d-flex justify-content-center ${Styles.listContainer}`}>
												{solutionList[this.state.solutionIndex]}
											</div>
										</div>
									</CSSTransition>
								</SwitchTransition>
								<div className={Styles.conveyorBeltContainer}>
									<ConveyorBelt
										activeIndex={this.state.solutionIndex}
										setActiveIndex={this.setsolutionIndex.bind(this)} />
									<img id="transportCube" className={`${Styles.transportCube} ${(this.state.showTransportCube) ? (null) : (Styles.transportCubeHidden)}`} src={solutionAssets('./transport-cube.svg')} alt="transport cube"/>
								</div>
							</>
						)
					}
				</div>
			</div>
		);
	}
}

export { SolutionsSection };
