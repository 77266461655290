import React from 'react';
import Styles from './positionCard.module.css';
import { updateCursor, cursorPointer } from '../../utils';

function PositionCard(props) {
	let tagStyle = null;

	if (props.position.tag === 'design') {
		tagStyle = Styles.designTag;
	} else if (props.position.tag === 'development') {
		tagStyle = Styles.developmentTag;
	}

	return (
		<div className={`pointer ${`${props.position.tag}PositionCard`} ${Styles.cardContainer}`} onClick={(e) => { props.showDetails(props.position, e); }} onMouseEnter={() => { updateCursor('blue'); cursorPointer(true); }} onMouseLeave={() => { updateCursor('white'); cursorPointer(false); }}>
			<div className={`${Styles.tag} ${tagStyle}`}></div>
			<div>
				<p className={Styles.title}>{props.position.title}</p>
				<p className={Styles.category}>
					{props.position.tag.charAt(0).toUpperCase() + props.position.tag.slice(1)}
				</p>
			</div>
		</div>
	);
}

export { PositionCard };
