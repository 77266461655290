import React from 'react';
import { updateCursor } from '../../../utils';
import Styles from './techHireSection.module.scss';
import Moon from '../../../images/moon-image.svg';

class TechHireSection extends React.Component {
	render() {
		return (
			<div id="techHireSection" className={Styles.mainContainer} onMouseEnter={() => updateCursor('blue')}>
				<div className="container row">
					<div className="col-lg-5 col-md-sm-12 m-0 my-3">
						<div className={Styles.techHireBlock}>
							<img src={Moon} className={Styles.moonImage} alt='Moon' />
							<div>
								<h2 className={Styles.techHireHeading}>
									Never<br />
									Make<br />
									Another<br />
									Tech Hire
								</h2>
							</div>
						</div>
					</div>
					<div className="container col-lg-7 col-md-12 col-md-12 p-0 m-0 my-3">
						<div className="container-row">
							<div className="container-fluid row flex-column-reverse flex-md-row justify-content-between p-0 m-0">
								<div className="col-md-6">
									<div className={Styles.solutionBlock}>
										<h4 className={Styles.solutionHeading}>High Quality</h4>
										<h5 className={Styles.solutionContent}>
											We consistently deliver high quality results that exceed expectations
										</h5>
									</div>
								</div>
								<div className="col-md-6">
									<div className={Styles.solutionBlock}>
										<h4 className={Styles.solutionHeading}>Responsive</h4>
										<h5 className={Styles.solutionContent}>
											Mobile or desktop, we’ve got it all covered with our responsive designs
										</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="row flex-column-reverse flex-md-row p-0 m-0 my-3">
							<div className="col-12">
								<div className={Styles.weOfferBlock}>
									<h2 className={Styles.weOfferText}>We Offer</h2>
									<div className={Styles.offeringContainer}>
										<h2 className={Styles.offeringText}>
											<span>High Quality</span>
											<span>Responsiveness</span>
											<span>Flexibility</span>
											<span>Scalablity</span>
										</h2>
									</div>
								</div>
							</div>
						</div>
						<div className="container-row">
							<div className="row flex-column-reverse flex-md-row p-0 m-0">
								<div className="col-md-6">
									<div className={Styles.solutionBlock}>
										<h4 className={Styles.solutionHeading}>Building Strong Partnerships</h4>
										<h5 className={Styles.solutionContent}>
											Your success is our success.
											This symbiotic relationship is how we build strong relationships
										</h5>
									</div>
								</div>
								<div className="col-md-6">
									<div className={Styles.solutionBlock}>
										<h4 className={Styles.solutionHeading}>Scalable Solutions</h4>
										<h5 className={Styles.solutionContent}>
											We write code that scales as you do.
											That way you can replace us if you need to but of course
											you’d never need to do that!
										</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export { TechHireSection };
