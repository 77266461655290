import React from 'react';
import Lottie from 'lottie-web';
import gsap from 'gsap';
import Styles from './careers.module.css';
import { updateCursor } from '../../utils';
import AstronautLottie from '../../lotties/astronaut.json';
import benefitIcon from '../../images/design.svg';
import { PositionCard } from '../positionCard';
import CareersData from '../../data/careers.json';

class Careers extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			openPositions: '',
			position: '',
			applyButtonStyle: '',
			showDetails: false,
			noPositionsError: false
		};
	}

	componentDidMount() {
		if (CareersData.length) {
			this.setState({
				openPositions: CareersData.map((position, index) => (
					<PositionCard key={index} position={position} showDetails={this.showDetails.bind(this)} />
				))
			});
		} else {
			this.setState({ noPositionsError: 'There are currently no positions available!' });
		}

		window.addEventListener('click', (e) => {
			if (this.state.showDetails && !document.getElementById('jobDetailsContainer').contains(e.target)) {
				this.hideDetails();
			}
		});

		window.addEventListener('resize', () => {
			this.hideDetails();
		});

		Lottie.loadAnimation({
			container: document.querySelector('#careersLottieContainer'),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: AstronautLottie
		});
	}

	async showDetails(position) {
		let applyButtonStyle = null;

		if (position.tag === 'design') {
			applyButtonStyle = Styles.designBtn;
		} else if (position.tag === 'development') {
			applyButtonStyle = Styles.developmentBtn;
		}

		await this.setState({
			position,
			applyButtonStyle
		});

		gsap.to('#jobDetailsContainer', {
			right: -20,
			duration: 0.2,
			onComplete: () => {
				this.setState({ showDetails: true });
			}
		});
	}

	showPositions(tag) {
		if (tag === 'all') {
			document.querySelectorAll('.designPositionCard, .developmentPositionCard, .otherPositionCard').forEach((item) => {
				item.style.display = 'flex';
			});

			this.setState({
				noPositionsError:
				(!document.querySelectorAll('.designPositionCard, .developmentPositionCard, .otherPositionCard').length) ? (
					'There are currently no positions available!'
				) : ('')
			});
		} else {
			document.querySelectorAll('.designPositionCard').forEach((item) => {
				item.style.display = (tag === 'design') ? ('flex') : ('none');
			});

			document.querySelectorAll('.developmentPositionCard').forEach((item) => {
				item.style.display = (tag === 'development') ? ('flex') : ('none');
			});

			document.querySelectorAll('.otherPositionCard').forEach((item) => {
				item.style.display = (tag === 'other') ? ('flex') : ('none');
			});

			this.setState({
				noPositionsError:
				(!document.querySelectorAll(`.${tag}PositionCard`).length) ? (
					'There are no positions available for this category!'
				) : ('')
			});
		}
	}

	async hideDetails() {
		gsap.to('#jobDetailsContainer', {
			right: (window.innerWidth < 768) ? ('-100vw') : ('-60vw'),
			duration: 0.2,
			onComplete: () => {
				this.setState({ showDetails: false });
			}
		});
	}

	render() {
		return (
			<div id="careersPage" className={Styles.careersContainer}>
				<div className={`row m-0 ${Styles.headingSection}`} onMouseEnter={() => updateCursor('white')}>
					<div className={`col-6 ${Styles.headingContainer}`}>
						<h2 className={Styles.mainHeading}>Want to build awesome stuff?</h2>
						<h3 className={Styles.joinText}>Join our team!</h3>
					</div>
					<div className={`col-6 ${Styles.astronautLottieContent}`}>
						<div className={Styles.astronautLottieContainer}>
							<div id="careersLottieContainer" />
						</div>
					</div>
				</div>
				<div className={Styles.benefitsSection} onMouseEnter={() => updateCursor('blue')}>
					<h2>Benefits</h2>
					<div className={`row ${Styles.benefits}`}>
						<div className={Styles.benefit}>
							<img className={Styles.benefitIcon} src={benefitIcon} alt="benefit icon" />
							<p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'}</p>
						</div>
						<div className={Styles.benefit}>
							<img className={Styles.benefitIcon} src={benefitIcon} alt="benefit icon" />
							<p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'}</p>
						</div>
						<div className={Styles.benefit}>
							<img className={Styles.benefitIcon} src={benefitIcon} alt="benefit icon" />
							<p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'}</p>
						</div>
						<div className={Styles.benefit}>
							<img className={Styles.benefitIcon} src={benefitIcon} alt="benefit icon" />
							<p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'}</p>
						</div>
						<div className={Styles.benefit}>
							<img className={Styles.benefitIcon} src={benefitIcon} alt="benefit icon" />
							<p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'}</p>
						</div>
						<div className={Styles.benefit}>
							<img className={Styles.benefitIcon} src={benefitIcon} alt="benefit icon" />
							<p>{'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.'}</p>
						</div>
					</div>
				</div>
				<div className={Styles.openingsSection} onMouseEnter={() => updateCursor('white')}>
					<h2>Open positions</h2>
					<div className={Styles.tagsContainer}>
						<button onClick={() => { this.showPositions('all'); }}>All</button>
						<button onClick={() => { this.showPositions('design'); }}>Design</button>
						<button onClick={() => { this.showPositions('development'); }}>Development</button>
						<button onClick={() => { this.showPositions('other'); }}>Other</button>
					</div>
					<div className={Styles.positionsContainer}>
						{this.state.openPositions}
						{this.state.noPositionsError ? <p>{this.state.noPositionsError}</p> : null}
					</div>
					<div id="jobDetailsContainer" className={Styles.positionDetailsContainer} onMouseEnter={() => updateCursor('blue')} onMouseLeave={() => updateCursor('white')}>
						<button className={`pointer ${Styles.closeDetailsBtn}`} onClick={() => { this.hideDetails(); }} type="button" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<h3>{this.state.position.title}</h3>
						<p className={Styles.detailsTag}>{this.state.position ? this.state.position.tag.charAt(0).toUpperCase() + this.state.position.tag.slice(1) : ''}</p>
						{
							this.state.position
							&& this.state.position.description.map((descriptionItem, index) => (
								<div key={index} id={`description${index}`}>
									{descriptionItem.heading ? <p className="font-weight-bold mt-5 mb-0">{descriptionItem.heading}</p> : null}
									{
										descriptionItem.text.length > 1
											? <ul className="m-0">
												{
													descriptionItem.text.map((item, i) => (
														<li className="mx-4" key={`listItem${i}`}>
															<p className="m-0">{item}</p>
														</li>
													))
												}
											</ul>
											: <p className="my-2">{descriptionItem.text}</p>
									}
								</div>
							))
						}
						<button className={`mt-4 pointer ${Styles.applyBtn} ${this.state.applyButtonStyle}`}>Apply</button>
					</div>
				</div>
			</div>
		);
	}
}

export { Careers };
