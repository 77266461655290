import React from 'react';
import Lottie from 'lottie-web';
import { Col, Row } from 'react-bootstrap';
import {
	CuttingEdge,
	ZonesOfMagic,
	Scalability,
	Collage,
} from '../../../images/zeta';
import { ReactComponent as Platform } from '../../../images/zeta/platform.svg';
import { ReactComponent as Brain } from '../../../images/zeta/brain.svg';
import { ReactComponent as Tube } from '../../../images/zeta/tube.svg';
import { CaseStudyHeroSection } from '../../caseStudyHeroSection';
import { ColorPalette } from '../components/colorPalette';
import { CtaSection } from '../components/ctaSection';
import { StarsLottie } from '../../../lotties/zeta';
import { TwoColumnGrid } from '../../../layouts/twoColumnGrid/twoColumnGrid';
import { updateCursor } from '../../../utils/index';
import PlanetZeta from '../../../images/zeta/planetZetaCtaSection.svg';
import SectionsData from '../sectionsData/zeta.json';
import ZetaHover from '../../../images/projects/zetaSurgical-hover.png';
import ZetaLandingPageGif from '../../../gif/zeta-landing-page.gif';
import ZetaPrimary from '../../../images/projects/zetaSurgical-primary.png';
import ZetaWhite from '../../../images/projects/zetaSurgical-white.png';
import Styles from './zeta.module.scss';

class ZetaScreen extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			backgroundColor: null,
			logo: ZetaPrimary,
		};
	}

	componentDidMount() {
		Lottie.loadAnimation({
			container: document.querySelector('#starsLottieContainer'),
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: StarsLottie
		});
	}

	render() {
		const colorPalette = {
			blue: '#00a0e9',
			black: '#282828',
			greyLight: '#efefef',
			greyDark: '#b4b4b4',
			purple: '#633cbb',
			orange: '#fd7730',
			orangeDark: '#e95200'
		};

		const {
			heroSection,
			cuttingEdge,
			neurosurgery,
			zoneOfMagic,
			rightTone,
			wowMoment,
			scalability
		} = SectionsData;

		return (
			<main className={Styles.mainContainer}>
				<CaseStudyHeroSection
					starsBackgroundHeader={heroSection.starsBackgroundHeader}
					caseStudyHeading={heroSection.caseStudyHeading}
					link={heroSection.link}
					sloganHeading={heroSection.sloganHeading}
					caseStudyText={heroSection.caseStudyText}
				/>
				<TwoColumnGrid
					gridStyles={Styles.cuttingEdgeGridContainer}
					isBackgroundWhite={true}
					revertOnMobile={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{cuttingEdge.title}</h4>
							<p className="mt-3">
								{cuttingEdge.paragraphOne}
								<br /><br />
								{cuttingEdge.paragraphTwo}
							</p>
						</article>
					}
					rightColumn={
						<img
							src={CuttingEdge}
							alt="Cutting edge technology"
							width="100%"
							onMouseEnter={() => updateCursor('white')}
						/>
					}
				/>
				<TwoColumnGrid
					gridStyles={Styles.neurosurgeryGridContainer}
					isBackgroundWhite={false}
					leftColumn={
						<div className={Styles.brainLottieContainer}>
							<div id="starsLottieContainer" className={Styles.starsLottie} />
							<Brain className={Styles.brain} />
							<Tube className={Styles.tube} />
							<Platform className={Styles.platform} />
						</div>
					}
					rightColumn={
						<div>
							<div className={Styles.textSection}>
								<h4 className="font-weight-bold">{neurosurgery.title}</h4>
								<p className="mt-3">
									{neurosurgery.paragraphOne}
									<br /><br />
									{neurosurgery.paragraphTwo}
								</p>
							</div>
						</div>
					}
				/>
				<TwoColumnGrid
					gridStyles={Styles.zoneOfMagicGridContainer}
					isBackgroundWhite={true}
					revertOnMobile={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{zoneOfMagic.title}</h4>
							<p className="mt-3">
								{zoneOfMagic.paragraphOne}
								<br /><br />
								{zoneOfMagic.paragraphTwo}
							</p>
						</article>
					}
					rightColumn={
						<img
							src={ZonesOfMagic}
							alt="Zone of Magic"
							className={Styles.mediaContainer}
							width="100%"
							onMouseEnter={() => updateCursor('white')}
							onMouseLeave={() => updateCursor('blue')}
						/>
					}
				/>
				<div className={Styles.rightToneSectionContainer} onMouseEnter={() => updateCursor('white')}>
					<Row className="flex-lg-row align-items-center">
						<Col
							sm={12}
							lg={6}
							className="container justify-content-center order-1 my-0 my-lg-5 mt-4"
							onMouseEnter={() => updateCursor('blue')}
							onMouseLeave={() => updateCursor('white')}
						>
							<ColorPalette
								primaryLogo={ZetaPrimary}
								whiteLogo={ZetaWhite}
								blackLogo={ZetaHover}
								colorPalette={colorPalette}
								gradients={[
									{
										background: `linear-gradient(333deg, ${colorPalette.purple} 33%, ${colorPalette.orangeDark} 66%)`,
										changeLogoTo: 'white'
									},
									{
										background: `linear-gradient(333deg, ${colorPalette.purple} 33%, ${colorPalette.blue} 66%)`,
										changeLogoTo: 'black'
									}
								]}
							/>
						</Col>
						<Col sm={12} lg={6} className="order-2">
							<div className={`${Styles.rightToneTextSection} d-flex flex-column justify-content-center`}>
								<h4 className="font-weight-bold mb-4">{rightTone.title}</h4>
								<p className="m-0">{rightTone.paragraphOne}</p>
							</div>
						</Col>
					</Row>
				</div>
				<TwoColumnGrid
					gridStyles={Styles.wowMomentGridContainer}
					isBackgroundWhite={true}
					revertOnMobile={true}
					leftColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{wowMoment.title}</h4>
							<p className="mt-3">
								{wowMoment.paragraphOne}
								<br /><br />
								{wowMoment.paragraphTwo}
							</p>
						</article>
					}
					rightColumn={
						<img
							src={ZetaLandingPageGif}
							alt="Zeta landing page"
							className={Styles.mediaContainer}
							width="100%"
							onMouseEnter={() => updateCursor('white')}
							onMouseLeave={() => updateCursor('blue')}
						/>
					}
				/>
				<TwoColumnGrid
					gridStyles={Styles.scalabilityGridContainer}
					isBackgroundWhite={false}
					leftColumn={
						<img
							src={Scalability}
							alt="Building a foundation of scalability"
							className={Styles.mediaContainer}
							width="100%"
						/>
					}
					rightColumn={
						<article className={Styles.textSection}>
							<h4 className="font-weight-bold">{scalability.title}</h4>
							<p className="mt-3">
								{scalability.paragraphOne}
								<br /><br />
								{scalability.paragraphTwo}
							</p>
						</article>
					}
				/>
				<div>
					<img src={Collage} alt="Collage" width="100%" />
				</div>
				<CtaSection planetImage={PlanetZeta} />
			</main>
		);
	}
}

export { ZetaScreen };
