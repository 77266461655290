import React from 'react';
import { Carousel } from 'react-bootstrap';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Styles from './prospectusSection.module.scss';
import { updateCursor, cursorPointer } from '../../../utils';
import StrategyIconSmall from '../../../images/strategy-small.svg';
import CodeIconSmall from '../../../images/code-small.svg';
import DesignIconSmall from '../../../images/design-small.svg';
import MaintainIconSmall from '../../../images/maintain-small.svg';

class ProspectusSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			carouselIndex: 0,
			carouselPaused: false,
			sectionIcon: StrategyIconSmall,
			backgroundTransition: null
		};
	}

	updateCarousel(pausedCheck, carouselIndex) {
		let sectionBackgroundIcon = '';
		const transitionTimeline = gsap.timeline();

		if (this.state.backgroundTransition) {
			this.state.backgroundTransition.kill();
		}

		this.setState({ backgroundTransition: transitionTimeline });

		transitionTimeline.to('.backgroundIcon', {
			opacity: 0,
			duration: 0.2,
			onComplete: () => {
				if (carouselIndex === 0) {
					sectionBackgroundIcon = StrategyIconSmall;
				} else if (carouselIndex === 1) {
					sectionBackgroundIcon = DesignIconSmall;
				} else if (carouselIndex === 2) {
					sectionBackgroundIcon = CodeIconSmall;
				} else if (carouselIndex === 3) {
					sectionBackgroundIcon = MaintainIconSmall;
				}

				this.setState({
					carouselPaused: pausedCheck,
					carouselIndex,
					sectionIcon: sectionBackgroundIcon
				});

				transitionTimeline.to('.backgroundIcon', { opacity: 0.2, duration: 0.2 });
			}
		});
	}

	componentDidMount() {
		document.querySelectorAll('.pointer').forEach((item) => {
			item.addEventListener('mouseover', () => {
				item.style.cursor = 'none !important';
				cursorPointer(true);
			});

			item.addEventListener('mouseout', () => {
				cursorPointer(false);
			});
		});

		gsap.registerPlugin(ScrollTrigger);

		ScrollTrigger.clearScrollMemory();

		const timeline = gsap.timeline({
			onStart: () => { document.getElementsByTagName('body')[0].style.overflowY = 'hidden'; },
			onComplete: () => { document.getElementsByTagName('body')[0].style.overflowY = 'scroll'; }
		});
		const fadeInAnimation = {
			opacity: 1,
			y: -10,
			duration: 0.4,
			pointerEvents: 'all'
		};

		gsap.to('.prospectusHeadingsContainer', { pointerEvents: 'none' });

		timeline.to('#prospectusSection', {
			background: '#fff',
			duration: 0.1,
			scrollTrigger: {
				trigger: '#prospectusSection',
				toggleActions: 'play none none none',
				start: 'top top+=150'
			},
			onComplete: () => {
				timeline.to('.headingContainer1', fadeInAnimation, '0.6')
					.to('.headingContainer2', fadeInAnimation, '<0.2')
					.to('.headingContainer3', fadeInAnimation, '<0.2')
					.to('.headingContainer4', fadeInAnimation, '<0.2')
					.to('.prospectusTextContainer', fadeInAnimation, '<0.5')
					.to('.prospectusHeadingsContainer', { pointerEvents: 'all', duration: 0 })
					.to('.backgroundIcon', { opacity: 0.2, duration: 0.2 }, '<0');
			}
		});
	}

	render() {
		return (
			<div id="prospectusSection" className={Styles.prospectusContainer} onMouseEnter={() => updateCursor('blue')}>
				<div className={`prospectusHeadingsContainer m-0 ${Styles.headingsContainer}`}>
					<div className={`headingContainer1 text-center ${Styles.sectionHeading}`}>
						<h4 className={`pointer ${Styles.heading} ${(this.state.carouselIndex === 0) ? (Styles.activeHeading) : (Styles.inactiveHeading)}`} onMouseEnter={() => this.updateCarousel(true, 0)}>Data Derived Strategy</h4>
					</div>
					<div className={`headingContainer2 text-center ${Styles.sectionHeading}`}>
						<h4 className={`pointer ${Styles.heading} ${(this.state.carouselIndex === 1) ? (Styles.activeHeading) : (Styles.inactiveHeading)}`} onMouseEnter={() => this.updateCarousel(true, 1)}>Interactive Experiential Design</h4>
					</div>
					<div className={`headingContainer3 text-center ${Styles.sectionHeading}`}>
						<h4 className={`pointer ${Styles.heading} ${(this.state.carouselIndex === 2) ? (Styles.activeHeading) : (Styles.inactiveHeading)}`} onMouseEnter={() => this.updateCarousel(true, 2)}>Quality Software Development</h4>
					</div>
					<div className={`headingContainer4 text-center ${Styles.sectionHeading}`}>
						<h4 className={`pointer ${Styles.heading} ${(this.state.carouselIndex === 3) ? (Styles.activeHeading) : (Styles.inactiveHeading)}`} onMouseEnter={() => this.updateCarousel(true, 3)}>Refine and Optimize Existing Code</h4>
					</div>
				</div>
				<div className={`prospectusTextContainer ${Styles.textContainer}`}>
					<Carousel
						interval={(this.state.carouselPaused) ? (null) : (5000)}
						slide={false}
						fade={true}
						indicators={false}
						nextIcon={null}
						prevIcon={null}
						activeIndex={this.state.carouselIndex}
						onSelect={(selectedIndex) => { this.updateCarousel(false, selectedIndex); }}>
						<Carousel.Item>
							<div className="pointer">
								<h5>The future of your company cannot be left up to chance.</h5>
								<p className="m-0">That is why we use data derived strategy to help your organization innovate for a resilient future.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item>
							<div className="pointer">
								<h5>Effective design isn't just about pretty colors and fancy pictures.</h5>
								<p className="m-0">It's a complex combination of aesthetically executing your vision but also delivering a highly optimized experience for your users. Our designers understand how to maximize your return on investment by creating an experience that your target audience will enjoy and will be motivated to use.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item>
							<div className="pointer">
								<h5>
									Writing code is something anyone can learn to do;
									writing scalable code that grows with you is tough.
								</h5>
								<p className="m-0">It takes years of experience designing systems from scratch to do it well. We leverage our extensive industry experience to understand your needs and deploy only the best of developers to make sure you don't get a product that has to be thrown out two years from now. Check out our <button className={`pointer ${Styles.stellarPromiseBtn}`} onClick={() => { window.scrollTo({ top: document.getElementById('stellarPromiseSection').offsetTop, behavior: 'smooth' }); }}>Stellar Promise</button> for how we guarantee our work!</p>
							</div>
						</Carousel.Item>
						<Carousel.Item>
							<div className="pointer">
								<h5>If you don't update and upgrade, the competition leaves you behind</h5>
								<p>
									Just like a finely crafted Rolls-Royce or Rolex may need maintenance
									so do your finely crafted apps and experiences.
								</p>
								<p className="m-0">Trust our experts to upgrade and maintain your company's future.</p>
							</div>
						</Carousel.Item>
					</Carousel>
				</div>
				<img className={`backgroundIcon ${Styles.sectionIconbackground}`} src={this.state.sectionIcon} alt={(this.state.sectionIcon) ? ('Section icon') : (' ')} />
			</div>
		);
	}
}

export { ProspectusSection };
