import Styles from './uniqueSolutionsSection.module.scss';
import UniqueSolutionsImage from '../../../images/unique-solutions.png';
import { updateCursor } from '../../../utils';

function UniqueSolutionsSection() {
	return (
		<div id="uniqueSolutionsSection" className={Styles.mainContainer} onMouseEnter={() => updateCursor('white')}>
			<div className={Styles.contentContainer}>
				<h3 className="font-weight-bold mb-5">Custom Built for your Unique Needs</h3>
				<p>
					Every business is has a unique approach and set of needs.
					Off the self tools can get you up and off the ground quickly
					but as your business scales, these tools can get in the way.
				</p>
				<p>
					Let us work with you to create the tools that unlock that
					next step of your growth instead of letting tools that work
					against you stand in your way.
				</p>
			</div>
			<div className={Styles.imageContainer}>
				<img src={UniqueSolutionsImage} alt="unique solutions" />
			</div>
		</div>
	);
}

export { UniqueSolutionsSection };
