import React from 'react';
import Styles from './cursor.module.scss';

function Cursor() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" id="cursor" className={Styles.cursor} width="30" height="30" viewBox="0 0 71 71" stroke="#fff" fill="#fff">
			<g transform="translate(-466 -449)">
				<circle id="cursorRing" cx="35.5" cy="35.5" r="34" transform="translate(466 449)" stroke="none" opacity="0.5" />
				<circle id="cursorPointer" className={Styles.cursorPointer} cx="9" cy="9" r="0" transform="translate(493 476)" stroke="none" fill="rgb(14, 15, 34)" opacity="0.4" />
				<g id="disabledCursor" className={Styles.disabledCursor} stroke="none" fill="#fff" fillOpacity="0">
					<circle transform="translate(466 449)" cx="35.5" cy="35.5" r="34" fill="#0F0F22"/>
					<path transform="translate(468 451) scale(2.23)" d="M15 27.5C18.1739 27.5 21.0716 26.3171 23.2761 24.368L4.33589 8.47533C3.17137 10.3746 2.5 12.6089 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z" />
					<path transform="translate(468 451) scale(2.23)" d="M6.14036 6.18202L25.2233 22.1945C26.6576 20.1601 27.5 17.6784 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C11.5376 2.5 8.40404 3.90771 6.14036 6.18202Z" />
				</g>
			</g>
		</svg>
	);
}

export { Cursor };
