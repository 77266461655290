export const cursorPointer = (pointerCheck) => {
	const pointer = document.getElementById('cursorPointer');

	pointer.r.baseVal.value = (pointerCheck) ? (28) : (0);
};

export const updateCursor = (variant) => {
	const cursor = document.getElementById('cursor');
	const pointer = document.getElementById('cursorPointer');

	if (variant === 'white') {
		cursor.style.fill = '#fff';
		pointer.style.fill = 'rgb(14, 15, 34)';
	} else if (variant === 'blue') {
		cursor.style.fill = 'rgb(14, 15, 34)';
		pointer.style.fill = '#fff';
	}
};

export const disableCursor = (disableCheck) => {
	const disabledCursor = document.getElementById('disabledCursor');

	disabledCursor.style.fillOpacity = (disableCheck) ? 0.4 : 0;
};

export const ENUMS = {
};
