import React from 'react';
import { Link } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import gsap from 'gsap';
import Styles from './projectsSection.module.scss';
import { updateCursor } from '../../../utils';
import { ProjectCard } from '../../projectCard';
import ProjectsData from '../../../data/projects.json';
import TagsData from '../../../data/tags.json';

class ProjectsSection extends React.Component {
	constructor(props) {
		super(props);

		this.state = { projectLogoBackground: '' };
	}

	setBackgroundLogo = (logo, project) => {
		this.setState({ projectLogoBackground: logo });

		document.getElementById('backgroundLogo').style.top = `${document.querySelector(`.${project}ProjectCard`).offsetTop + document.querySelector(`.${project}ProjectCard`).offsetHeight - 120}px`;
	};

	projectCardHover(mouseOverCheck) {
		gsap.to('#seeMoreText', 0.6, { color: mouseOverCheck ? '#fff' : '#5a5b5c' });

		gsap.to('#projectsSection', 0.6, {
			background: mouseOverCheck ? `#${tinycolor('#0e0f22').lighten().toString().slice(1, 7)}` : '#fff'
		});

		gsap.to('.seeMoreCard', 0, { background: mouseOverCheck ? '#0e0f22' : '#fff' }, 0);
	}

	filterProjects = () => {
		const filteredProjects = ProjectsData.map((projectData) => {
			const matchingTagIDs = projectData.tags || [];

			const matchingTags = TagsData
				.filter((tagData) => matchingTagIDs.includes(tagData.id))
				.map((tagData) => tagData.name);

			return {
				...projectData,
				tags: matchingTags,
			};
		});

		return filteredProjects;
	};

	render() {
		const filteredProjects = this.filterProjects();

		return (
			<div id="projectsSection" className={Styles.projectsSection} onMouseEnter={() => updateCursor('blue')}>
				<h1 id="projectsSectionHeading" className={`font-weight-bold text-center ${Styles.projectsHeading}`}>Projects</h1>
				<div className={Styles.projectsContainer}>
					<div className={`container ${Styles.projectsCards}`}>
						{
							filteredProjects.map((project, index) => {
								if (index < 7) {
									return (
										<ProjectCard
											key={index}
											name={project.name}
											color={project.color}
											url={project.url}
											description={project.description}
											tags={project.tags}
											setBackgroundLogo={this.setBackgroundLogo} />
									);
								}
								return null;
							})
						}
						<div className={Styles.seeMoreCardContainer}>
							<Link
								to="/projects"
								className={`pointer seeMoreCard ${Styles.seeMoreCard}`}
								onMouseEnter={() => this.projectCardHover(true)}
								onMouseLeave={() => this.projectCardHover(false)}>
								<p id="seeMoreText">See More</p>
							</Link>
						</div>
					</div>
				</div>
				<div id="backgroundLogo" className={Styles.backgroundLogoContainer}>
					<img className={Styles.projectLogoBackground} src={this.state.projectLogoBackground} alt={(this.state.projectLogoBackground) ? ('Project logo') : (' ')} />
				</div>
			</div>
		);
	}
}

export { ProjectsSection };
